html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #fff8dc;
}

#root {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.container-fluid, .row {
    height: 100%;
}

.error {
    color: red;
}

@page {
    size: A4;
    margin: 0; /* 余白を完全に取り除く */
}

.page {
    width: 210mm;
    height: 297mm;
    padding-top: 30.5mm;
    padding-left: 23mm;
    box-sizing: border-box;
    background-color: #FFFFFF;
}

.qr-grid {
    display: grid;
    grid-template-columns: repeat(7, 20mm);
    grid-template-rows: repeat(10, 20mm);
    column-gap: 4mm;
    row-gap: 4mm;
    /* background-color: #00FF0033; */
}

.qr-code-container {
    width: 20mm;
    height: 20mm;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 1px solid black;
    /*background-color: #0000FF33;*/
}

.qr-code-wrapper {
    width: 75%;
    height: 75%;
    margin-top: 1.5mm; /* 画像の上に1.5mmの余白を追加 */
    display: flex;
    align-items: center;
    justify-content: center;
}

.qr-code-container canvas {
    width: 100%;
    height: 100%;
}

.qr-code-container p {
    margin: 0;
    font-size: 2mm;
    text-align: center;
}

.left-column {
    background-color: #f5f5f5;
    height: fit-content;
}

@media (min-width: 768px) { /* md 以上のブレークポイント */
    .left-column {
        height: 100%;
    }
}

.right-column {
    background-color: #fff8dc;
    height: 100%;
}

.scan-top {
    background-color: #000000;
    height: 50%;
    display: flex;
    justify-content: center; /* 水平方向の中央揃え */
    align-items: center; /* 垂直方向の中央揃え */
    position: relative;
}

.scan-bottom {
    height: 45%;
}

.scan-container {
    height: 90%;
}

.scan-bound {
    color: #FFFFFF;
    position: absolute;
    left: 0;
    top: 0;
}

.scan-storage-name {
    color: #FFFFFF;
    position: absolute;
    left: 0;
    top: 40px;
}

@media print {
    body {
        margin: 0;
        padding: 0;
    }

    .page {
        background-color: white;
    }
    
    .qr-grid {
        background-color: white;
    }
    
    .qr-code-container {
        background-color: white;
    }

    .no-print {
        display: none !important;
    }
}
